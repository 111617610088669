import React, {useState, useEffect} from 'react';
import { formatDate, formatOrderAmount, formatOrderAddress, formatPaidAmount } from '../utils/FormatHelper';
import { getOrderTypeName } from '../utils/MappingHelper';
import { useTranslation } from 'react-i18next';
import { FwTooltip } from "@freshworks/crayons/react";

const OrderBody = ({ order, onViewOrder, shippingProfiles, mode, orderReferrers, webstores }) => {
    const { t } = useTranslation();

    const [currentReferrerName, setCurrentReferrerName] = useState(null);
    const [currentWebstoreName, setCurrentWebstoreName] = useState(null);


    useEffect(() => {
        if (orderReferrers && orderReferrers.length && order && order.referrerId !== null) {
            console.log("orderReferrers xyxyxxy", orderReferrers);
            const currentOrderReferrer = orderReferrers.find(referrer => parseFloat(referrer.id) === parseFloat(order.referrerId));
            setCurrentReferrerName(currentOrderReferrer ? currentOrderReferrer.backendName : '');
        }
    }, [order, orderReferrers])

    useEffect(() => {
        if (webstores && webstores.length && order && order.plentyId !== null) {
            const currentWebstore = webstores.find(webstore => webstore.id === order.plentyId);
            setCurrentWebstoreName(currentWebstore ? currentWebstore.name : '');
        }
    }, [order, webstores])




    const getPropertyValue = (typeId) => {
        const property = order.properties && order.properties.find(p => p.typeId === typeId);
        return property ? property.value : null;
    }

    const getDatesValue = (typeId) => {
        //typeid 5 = Warenausgang gebucht
        const property = order.dates && order.dates.find(d => d.typeId === typeId);
        return property ? property.date : null;
    }

    const getFormattedAddressByType = (typeId) => {
        //typeid 2 = Lieferadresse
        const address = order.addresses && order.addresses.find(a => a.pivot.typeId === typeId);
        return address ? formatOrderAddress(address) : null;
    }

    const getShippingProfileName = () => {
        const shippingProfileId = getPropertyValue(2);
        console.log("shippingProfileId", shippingProfileId);
        if(!shippingProfileId) return '-';
        const shippingProfile = shippingProfiles && shippingProfiles.find(sp => sp.id == shippingProfileId);
        return shippingProfile ? shippingProfile.name : '-';
    }

    const getOrderEmail = () => {
        const addresses = order.addresses;

        if (!addresses || !Array.isArray(addresses)) {
          return '-';
        }
      
        // Look for the address with typeId = 2 in pivot
        let emailOption = findEmailOption(addresses, 2);
      
        // If not found, look for the address with typeId = 1 in pivot
        if (!emailOption) {
          emailOption = findEmailOption(addresses, 1);
        }
      
        return emailOption;
      };
      
      const findEmailOption = (addresses, typeId) => {
        // Find an address with the given typeId in pivot
        const address = addresses.find(a => a.pivot && a.pivot.typeId === typeId);
      
        // If an address is found and has options, look for option with typeId = 5
        if (address && Array.isArray(address.options)) {
          const emailOption = address.options.find(opt => opt.typeId === 5);
      
          // If the option with typeId = 5 is found, return the value
          if (emailOption) {
            return emailOption.value;
          }
        }
      
        return '-';
      };




    return (
        <div>


            <div className="flex flex-wrap p-1">

            <div className="flex flex-col w-1/2 mt-1">
                    <h2 className="text-gray-400 text-xs font-semibold">
                        {t('order_type')}
                    </h2>
                    <p className="text-dark text-gray-800 text-xs mt-1">                       
                       <span data-test-id="order_type" className={`rounded-xs p-0.5 border border-dotted ${getOrderTypeName(order.typeId) === 'TYPE_SALES_ORDER' ? "bg-gray-100" : "bg-orange-100"}`}> {t(getOrderTypeName(order.typeId))}</span>
                    </p>
                </div>

                {/* {mode !== 'search' && (
                <div className="flex flex-col w-1/2 mt-1">
                    <h2 className="text-gray-400 text-xs font-semibold">
                        {t('amount')}
                    </h2>
                    <p data-test-id="order_amount" className="text-dark text-gray-800 text-xs mt-1">
                        {formatOrderAmount(order)}
                    </p>
                </div>
                )} */}

                {mode !== 'search' && (
                <div className="flex flex-col w-1/2 mt-1">
                    <h2 className="text-gray-400 text-xs font-semibold">
                        {t('amount')}
                    </h2>
                    <p data-test-id="order_amount" className="text-dark text-gray-800 text-xs mt-1">
                        {formatOrderAmount(order)}
                    </p>
                    <FwTooltip content={ t('amount_paid')}>
                        <p data-test-id="order_amount" className="text-dark text-green-800 text-xxs mt-1">
                            {formatPaidAmount(order)}
                        </p>      
                    </FwTooltip>
                    
                </div>
                )}



                {mode !== 'search' && (
                <div className="flex flex-col w-1/2 mt-2.5">
                    <h2 className="text-gray-400 text-xs font-semibold">
                        {t('shippingProfile')}
                    </h2>
                    <p data-test-id="shipping_profile" className="text-dark text-gray-800 text-xs mt-1">
                        {getShippingProfileName()}
                    </p>
                </div>
                )}

                {/* {mode !== 'search' && (
                <div className="flex flex-col w-1/2 mt-2.5">
                    <h2 className="text-gray-400 text-xs font-semibold">
                        {t('amount_paid')}
                    </h2>
                    <p data-test-id="order_amount" className="text-dark text-gray-800 text-xs mt-1">
                        {formatPaidAmount(order)}
                    </p>
                </div>
                )} */}

                {mode !== 'search' && (
                <div className="flex flex-col w-1/2 mt-2.5">
                    <h2 className="text-gray-400 text-xs font-semibold">
                        {t('warehouse')}
                    </h2>
                    <p data-test-id="order_warehouse" className="text-dark text-gray-800 text-xs mt-1">
                    {order.warehouseSender && ( order.warehouseSender.name ) || "-" }
                    </p>
                </div>
                )}

                {mode !== 'search' && (
                <div className="flex flex-col w-1/2 mt-2.5">
                    <h2 className="text-gray-400 text-xs font-semibold">
                        {t('booking_date')}
                    </h2>
                    <p data-test-id="order_booking_date" className="text-dark text-gray-800 text-xs mt-1">
                        {getDatesValue(5) && ( formatDate(getDatesValue(5))  ) || "-" } 
                    </p>
                </div>
                )}

                <div className="flex flex-col w-1/2 mt-2.5">
                    <h2 className="text-gray-400 text-xs font-semibold">
                        {t('ext_order_nr')}
                    </h2>
                    <p data-test-id="order_external_order_id" className="text-dark text-gray-800 text-xs mt-1">
                    {getPropertyValue(7) && ( getPropertyValue(7)  ) || "-" } 
                    </p>
                </div>

                {mode !== 'search' && (
                <div className="flex flex-col w-1/2 mt-2.5">
                    <h2 className="text-gray-400 text-xs font-semibold">
                        {t('orderReferrer')}
                    </h2>
                    <p data-test-id="order_referrer" className="text-dark text-gray-800 text-xs mt-1">
                    {currentReferrerName}
                    </p>
                </div>
                )}

                {mode !== 'search' && (
                <div className="flex flex-col w-1/2 mt-2.5">
                    <h2 className="text-gray-400 text-xs font-semibold">
                        {t('webstore')}
                    </h2>
                    <p data-test-id="order_referrer" className="text-dark text-gray-800 text-xs mt-1">
                    {currentWebstoreName}
                    </p>
                </div>
                )}

                <div className="flex flex-col w-full mt-2.5">
                    <h2 className="text-gray-400 text-xs font-semibold">
                        E-Mail
                    </h2>
                    <p data-test-id="order_email" className="text-dark text-gray-800 break-all text-xs mt-1">
                        {getOrderEmail()}
                    </p>
                </div>

                <div className="flex flex-col w-full mt-2.5">
                    <h2 className="text-gray-400 text-xs font-semibold">
                         {t('shipping_address')}
                    </h2>
                    <p data-test-id="order_delivery_address" className="text-dark text-gray-800 text-xs mt-1">
                        {getFormattedAddressByType(2) && ( getFormattedAddressByType(2) ) || "-" }
                    </p>
                </div>

            </div>

        </div>
    );
};

export default OrderBody;
