


const getOrderTypeName = (orderTypeId) => {
    const orderType = orderTypes.find(t => t.id === orderTypeId);
    return orderType ? orderType.n : '';
}

const getOrderReferences = (order) => {
    // Get originOrderReferences and orderReferences from order
        // Provide empty arrays as default values in case they are missing
        const originOrderReferences = order?.originOrderReferences || [];
        const orderReferences = order?.orderReferences || [];
        console.log("originOrderReferences", originOrderReferences);
        console.log("orderReferences", orderReferences);
        // Map each references to an object with only referenceOrderId and referenceType
        const originRefs = originOrderReferences.map(ref => ({
            referenceOrderId: ref.orderId,
            referenceType: ref.referenceType,
            isLoaded: false,
        }));

        const refs = orderReferences.map(ref => ({
            referenceOrderId: ref.referenceOrderId,
            referenceType: ref.referenceType,
            isLoaded: false,
        }));

        // Concatenate the two lists
        const allReferences = [...originRefs, ...refs];
        return allReferences;
}

    
const orderTypes = [
    { id: 1, n: "TYPE_SALES_ORDER" },
    { id: 2, n: "TYPE_DELIVERY_ORDER" },
    { id: 3, n: "TYPE_RETURN" },
    { id: 4, n: "TYPE_CREDIT_NOTE" },
    { id: 5, n: "TYPE_WARRANTY" },
    { id: 6, n: "TYPE_REPAIR" },
    { id: 7, n: "TYPE_OFFER" },
    { id: 8, n: "TYPE_ADVANCE_ORDER" },
    { id: 9, n: "TYPE_MULTI_ORDER" },
    { id: 10, n: "TYPE_MULTI_CREDIT_NOTE" },
    { id: 11, n: "TYPE_MULTI_DELIVERY" },
    { id: 12, n: "TYPE_REORDER" },
    { id: 13, n: "TYPE_PARTIAL_DELIVERY" },
    { id: 14, n: "TYPE_SUBSCRIPTION" },
    { id: 15, n: "TYPE_REDISTRIBUTION" }
];




const getCountryCode = (countryId) => {
    const country = countryList.find(c => c.id === countryId);
    return country ? country.n : '';
}

const countryList = [
    { id: 1, n: 'DE' },
    { id: 2, n: 'AT' },
    { id: 3, n: 'BE' },
    { id: 4, n: 'CH' },
    { id: 5, n: 'CY' },
    { id: 6, n: 'CZ' },
    { id: 7, n: 'DK' },
    { id: 8, n: 'ES' },
    { id: 9, n: 'EE' },
    { id: 10, n: 'FR' },
    { id: 11, n: 'FI' },
    { id: 12, n: 'GB' },
    { id: 13, n: 'GR' },
    { id: 14, n: 'HU' },
    { id: 15, n: 'IT' },
    { id: 16, n: 'IE' },
    { id: 17, n: 'LU' },
    { id: 18, n: 'LV' },
    { id: 19, n: 'MT' },
    { id: 20, n: 'NO' },
    { id: 21, n: 'NL' },
    { id: 22, n: 'PT' },
    { id: 23, n: 'PL' },
    { id: 24, n: 'SE' },
    { id: 25, n: 'SG' },
    { id: 26, n: 'SK' },
    { id: 27, n: 'SI' },
    { id: 28, n: 'US' },
    { id: 29, n: 'AU' },
    { id: 30, n: 'CA' },
    { id: 31, n: 'CN' },
    { id: 32, n: 'JP' },
    { id: 33, n: 'LT' },
    { id: 34, n: 'LI' },
    { id: 35, n: 'MC' },
    { id: 36, n: 'MX' },
    { id: 37, n: 'IC' },
    { id: 38, n: 'IN' },
    { id: 39, n: 'BR' },
    { id: 40, n: 'RU' },
    { id: 41, n: 'RO' },
    { id: 42, n: 'EA' },
    { id: 43, n: 'EA' },
    { id: 44, n: 'BG' },
    { id: 45, n: 'XZ' },
    { id: 46, n: 'KG' },
    { id: 47, n: 'KZ' },
    { id: 48, n: 'BY' },
    { id: 49, n: 'UZ' },
    { id: 50, n: 'MA' },
    { id: 51, n: 'AM' },
    { id: 52, n: 'AL' },
    { id: 53, n: 'EG' },
    { id: 54, n: 'HR' },
    { id: 55, n: 'MV' },
    { id: 56, n: 'MY' },
    { id: 57, n: 'HK' },
    { id: 58, n: 'YE' },
    { id: 59, n: 'IL' },
    { id: 60, n: 'TW' },
    { id: 61, n: 'GP' },
    { id: 62, n: 'TH' },
    { id: 63, n: 'TR' },
    { id: 64, n: 'GR' },
    { id: 65, n: 'ES' },
    { id: 66, n: 'NZ' },
    { id: 67, n: 'AF' },
    { id: 68, n: 'AX' },
    { id: 69, n: 'DZ' },
    { id: 70, n: 'AS' },
    { id: 71, n: 'AD' },
    { id: 72, n: 'AO' },
    { id: 73, n: 'AI' },
    { id: 74, n: 'AQ' },
    { id: 75, n: 'AG' },
    { id: 76, n: 'AR' },
    { id: 77, n: 'AW' },
    { id: 78, n: 'AZ' },
    { id: 79, n: 'BS' },
    { id: 265, n: 'XI' },
    ];

    export {getCountryCode, getOrderTypeName, getOrderReferences};